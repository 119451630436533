import config from "../conf";
import axios from "axios";

class HttpRequest {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.queue = {};
  }

  getInsideConfig() {
    const config = {
      baseURL: this.baseUrl,
      headers: { Authorization: `Basic ${localStorage.getItem("id_token")}` },
    };
    return config;
  }

  destroy(url) {
    delete this.queue[url];
    if (!Object.keys(this.queue).length) {
    }
  }

  interceptors(instance, url) {
    // 请求拦截
    instance.interceptors.request.use(
      (config) => {
        // 添加全局的loading...
        if (!Object.keys(this.queue).length) {
        }
        this.queue[url] = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    // 响应拦截
    instance.interceptors.response.use(
      (res) => {
        this.destroy(url);
        const { data, status } = res;
        return { data, status };
      },
      (error) => {
        this.destroy(url);
        return Promise.reject(error);
      }
    );
  }

  request(options) {
    const instance = axios.create();
    const mergeOptions = Object.assign(this.getInsideConfig(), options);
    this.interceptors(instance, mergeOptions.url);
    return instance(mergeOptions);
  }
}

const HttpRequestObject = new HttpRequest(config);
export default HttpRequestObject;
