import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "styles/reset.css";

import { Index } from "pages/index/index";
import { Qrcode } from "pages/qrcode/qrcode";
import JobList from "pages/jobList/jobList";
import JobDetail from "pages/jobDetail/jobDetail";

function App() {
  return (
    <Switch>
      <Route path="/index" component={Index}></Route>
      <Route path="/qrcode" component={Qrcode}></Route>
      <Route path="/joblist" component={JobList}></Route>
      <Route path="/jobdetail" component={JobList}></Route>
      <Redirect exact from="/" to="/index"></Redirect>
    </Switch>
  );
}

export default App;
