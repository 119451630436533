import React, { Component } from "react";
import styles from "./jobDetail.module.scss";

class JobDetail extends Component {
  constructor() {
    super();
    this.state = {
      jobDetail: {},
    };
  }
  render() {
    const { person } = this.state;
    return <div className={styles.content}>Wow !</div>;
  }
}

export default JobDetail;
