import React , {PureComponent}from 'react'
import IndexUI from './indexUI'

import http from 'https/http'
import API_URL from 'src/conf.js'
class Index extends  PureComponent {

    render(){
        return(
            <>
                <IndexUI></IndexUI>
            </>
        )
    }

   async componentDidMount(){
        let res = await http.get({url:`${API_URL}invitation?sessionToken=zZNJfYAhCzORHtVY5iVLQ1XNPX`})
        console.log(res)
    }

}

export default Index 