import React, { Component } from "react";
import styles from "./jobList.module.scss";
import { fetchJobCollectionInfo, fetchJobDetailInfo } from "../../api/Position";
import Paper from "@material-ui/core/Paper";
import DocumentTitle from "react-document-title";

class JobList extends Component {
  constructor() {
    super();
    this.state = {
      jobList: [],
      jobListQrcodeUrl: "",
    };
  }
  componentDidMount() {
    const param = this.props.location.search;
    let token = "";
    if (param) {
      const tokens = param.split("=");
      if (tokens.length > 1) {
        token = tokens[1];
      }
      console.log("token");
      console.log(token);
      if (tokens[0] === "?positionToken") {
        this.fetchJobDetail(token);
      } else if (tokens[0] === "?jobListToken") {
        this.fetchJobCollection(token);
      }
    }
    // t1服务器测试token
    //token = "J9wYZHHcNLEkYsUhO9zGQEO9AZ";
    // t1服务器测试token
  }
  fetchJobCollection(token) {
    fetchJobCollectionInfo(token).then((res) => {
      if (res.data.code == 0) {
        console.log(res.data);
        this.setState({
          jobList: res.data.data.list,
          jobListQrcodeUrl: res.data.data.qrcodeURL,
        });
      } else {
        // TODO: 报错题型
      }
    });
  }
  fetchJobDetail(token) {
    fetchJobDetailInfo(token).then((res) => {
      if (res.data.code === 0) {
        console.log(res.data);
        this.setState({
          jobList: [res.data.data],
          jobListQrcodeUrl: res.data.data?.shareInfo?.quCodeUrl,
        });
      } else {
        // TODO: 报错题型
      }
    });
  }
  getFormatSalaryNumStrWithK(from, to, type) {
    const typeStr = this.getSalaryDesc(type);
    let f = from >= 1000 ? Math.round((from / 1000) * 10) / 10 + "k" : from;
    let t = to >= 1000 ? Math.round((to / 1000) * 10) / 10 + "k" : to;
    if (type === 5) {
      return typeStr;
    } else {
      return f === t ? f : f + "-" + t;
    }
  }
  getSalaryDesc(type) {
    switch (type) {
      case 0:
        return "";
      case 1:
        return "月";
      case 2:
        return "日";
      case 3:
        return "时";
      case 4:
        return "年";
      case 5:
        return "薪资面议";
      default:
        return "";
    }
  }
  getDegreeDesc(degree) {
    switch (degree) {
      case 0:
        return "不限学历";
      case 1:
        return "初中以下学历";
      case 2:
        return "初中学历";
      case 3:
        return "高中学历";
      case 4:
        return "职高学历";
      case 5:
        return "中专学历";
      case 6:
        return "大专学历";
      case 7:
        return "本科学历";
      case 8:
        return "硕士学历";
      case 9:
        return "博士学历";
      case 99:
        return "其他学历";
      default:
        return "其他学历";
    }
  }
  getExperienceDesc(experience) {
    switch (experience) {
      case 0:
        return "不限";
      case 1:
        return "应届生";
      case 2:
        return "1-3年工作经验";
      case 3:
        return "3-5年工作经验";
      case 4:
        return "5-10年工作经验";
      case 5:
        return "10年以上工作经验";
      default:
        return "";
    }
  }
  renderCard(job) {
    let { jobListQrcodeUrl } = this.state;
    if (
      job.shareInfo &&
      job.shareInfo.qrCodeUrl &&
      job.shareInfo.qrCodeUrl.length > 10
    ) {
      jobListQrcodeUrl = job.shareInfo.qrCodeUrl;
    }
    const experience = this.getExperienceDesc(job.requirement?.workExperience);
    const degree = this.getDegreeDesc(job.requirement?.degree);
    const salary = this.getFormatSalaryNumStrWithK(
      job.salary?.from,
      job.salary?.to,
      job.salary?.type
    );
    const unitStr = this.getSalaryDesc(job.salary?.type);
    return (
      <Paper elevation={3} className={styles.cardDiv}>
        <div className={styles.cardDiv1}>
          <div className={styles.department}>
            {job.enterprise?.name}&nbsp;·&nbsp;{job.department?.name}
          </div>
          <div className={styles.namesalary}>
            <div className={styles.name}>{job.name}</div>
            {/* <div className={styles.salary}>
              {salary}
              {job.salary?.type === 5 ? (
                ""
              ) : (
                <span className={styles.salaryUnit}>元/{unitStr}</span>
              )}
            </div> */}
          </div>
          <div className={styles.requirement}>
            {job.location?.city}&nbsp;·&nbsp;{experience}&nbsp;·&nbsp;{degree}
          </div>
          <div className={styles.tags}>
            {job.treatments?.map((tag) => {
              return <div className={styles.tag}>{tag}</div>;
            })}
          </div>
          <div className={styles.requireTitle}>职位要求</div>
          <div className={styles.require}>{job.desc}</div>
          <div className={styles.qrcodeDiv}>
            <img className={styles.qrcode} src={jobListQrcodeUrl} alt=""></img>
            <div className={styles.desc}>
              微信扫码了解
              {/* <span className={styles.greenText}>更多信息</span>或
              <span className={styles.greenText}>申请职位</span> */}
            </div>
          </div>
        </div>
      </Paper>
    );
  }
  render() {
    const { jobList } = this.state;
    const enterpriseName =
      jobList && jobList.length > 0 ? jobList[0]?.enterprise?.name : "";

    return (
      <DocumentTitle title={enterpriseName}>
        <div className={styles.content}>
          <div className={styles.head}>
            <div className={styles.logo}>{enterpriseName}</div>
          </div>
          <div className={styles.body}>
            <div className={styles.section}>热招岗位</div>
            {jobList &&
              jobList.map((job) => {
                return this.renderCard(job);
              })}
            <div className={styles.footerMargin}></div>
            <div className={styles.footer}>
              鳄梨科技版权所有 copyright ©️ elihr.cn | 京ICP备19043412号-2
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default JobList;
