import HttpRequestObject from "./Api";

export const fetchJobCollectionInfo = (token) => {
  return HttpRequestObject.request({
    url: `/position/collection?token=${token}`,
    method: "GET",
  });
};

export const fetchJobDetailInfo = (token) => {
  return HttpRequestObject.request({
    url: `/position/detail?token=${token}`,
    method: "GET",
  });
};
