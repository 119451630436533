import styled from 'styled-components'

const StyledQrcode = styled.div`
    width:100%;
    height:100%;
    overflow-y:scroll;
    .qrcodeContent{
        width:100%;
        height:100%;
        display:flex;
        flex-direction:column;
        align-items:center;
        .qrcode{
            width:3.17rem;
            height:100%;
            
            .scanCodeContent{
                padding-top:.4rem;
                width:100%;
                height:3.65rem;
                display:flex;
                flex-direction:column;
                align-items:center;
                .scanCodeTip{
                    width:100%;
                    min-height:.5rem;
                    font-size:.18rem;
                }
                .scanImage{
                    flex:1;
                    margin-top:.27rem;
                    position: relative;
                    >img{
                        width:1.6rem;
                        height:2.04rem;
                    }
                    .qrcodeImgWrap{
                        width:.78rem;
                        height:.78rem;
                        border-radius:50%;
                        background-color:#fff;
                        position: absolute;
                        top:.95rem;
                        left:.41rem;
                        .qrcodeImgSize{
                            width:100%;
                            height:100%;
                            border-radius:50%;
                        }
                    }
                }
                
            }

            .passTipContent{
                width:100%;
                height:2rem;
                display:flex;
                flex-direction:column;
                align-items:center;
                margin-top:.2rem;
                .passTipTitle{
                    width:100%;
                    min-height:.25rem;
                    font-size:.18rem;
                    display:flex;
                    justify-content:center;
                    
                }
                .passTipImg{
                    margin-top:.6rem;
                    >img{
                        width:2.19rem;
                        height:1.64rem;
                    }
                }
            }
        }
    }
`

export{
    StyledQrcode
}
