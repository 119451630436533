import React,{PureComponent} from 'react'

import QrcodeUI from './qrcodeUI'
import http from 'https/http'
import API_URL from 'src/conf.js'

class Qrcode extends PureComponent {

    state={
        qrcodeUrl:''
    }

    render(){
        return(
            <QrcodeUI qrcodeUrl={this.state.qrcodeUrl}>

            </QrcodeUI>
        )
    }

    async componentDidMount(){
        //console.log((this.props.location.search).slice(7))

        let res = await http.get({url:`${API_URL}invitation?sessionToken=${(this.props.location.search).slice(7)}`})
        console.log(res.data.data.qrcodeUrl)
        this.setState({
            qrcodeUrl:res.data.data.qrcodeUrl
        })
    }
}

export default Qrcode