const conf = {
  development: {
    config: {
      local: "http://39.105.25.169:10001/",
      test: "http://47.94.13.0:10002/",
      testu: "http://39.97.173.151:10002/",
      staging: "https://staging.erbg.ren/api/",
    },
  },
  production: {
    config: {
      prod: "https://c.elihr.cn/",
      staging: "https://staging.erbg.ren/api/",
      testu: "http://39.97.173.151:10002/",
      test: "https://test.erbg.ren/api/",
    },
  },
};

const API_URL = conf[process.env.NODE_ENV].config[process.env.REACT_APP_ENV];
export default API_URL;

//部署的时候需要在生成服务器上设置如下环境变量
//$vi /etc/profile
//export REACT_APP_ENV=prod

//部署的时候需要在staging服务器上设置如下环境变量
//$vi /etc/profile
//export REACT_APP_ENV=staging

//部署的时候需要在test服务器上设置如下环境变量
//$vi /etc/profile
//export REACT_APP_ENV=test
