import React from 'react'

import {StyledQrcode} from "../../qrcode/views/styledQrcode";

function IndexUI(){

    return(

        <StyledQrcode>
            <div className="qrcodeContent">
                <div className="qrcode">
                    <div className="scanCodeContent">
                        <div className="scanCodeTip">
                            1. 长按或截图保存下方小程序码后使用微信识别
                        </div>
                        <div className='scanImage'>
                            <img src='https://public-static-assets.oss-cn-beijing.aliyuncs.com/img/elisingle.png'>

                            </img>
                            <div className='qrcodeImgWrap'>
                                <img src="https://public-static-assets.oss-cn-beijing.aliyuncs.com/img/elihrqrcode.jpg" className='qrcodeImgSize'></img>
                            </div>
                        </div>

                    </div>
                    <div className='passTipContent'>
                        <div className='passTipTitle'>
                            2. 进入面试流程即可极速完成面试
                        </div>
                        <div className='passTipImg'>
                            <img src='https://public-static-assets.oss-cn-beijing.aliyuncs.com/img/elidouble.png'></img>
                        </div>
                    </div>
                </div>
            </div>
        </StyledQrcode>
    )
}

export default IndexUI
