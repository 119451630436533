import axios from 'axios';

export default {
    get:({url}) => {
        return axios({
            url,
            method:'GET',
        }).then((res)=>{
            return res
        })
    },
    post:({url,data}) => {
        return axios({
            url,
            method:'POST',
            data,
        }).then((res) => {
            return res
        })
    },
    delete:({url,data}) => {
        return axios({
            url,
            method:'DELETE',
            data,
        })
    },

}
