import React from 'react'

import {StyledQrcode} from './styledQrcode'

function QrcodeUI(props){
    console.log(props.qrcodeUrl)
    return(
        <StyledQrcode>
            <div className="qrcodeContent">
                <div className="qrcode">
                    <div className="scanCodeContent">
                        <div className="scanCodeTip">
                        1.截图保存下方小程序码后使用微信识别 
                        </div>
                        <div className='scanImage'>
                            <img src='https://public-static-assets.oss-cn-beijing.aliyuncs.com/img/elisingle.png'>
                            </img>
                            <div className='qrcodeImgWrap'>
                                {
                                    props.qrcodeUrl ? <img src={props.qrcodeUrl} className='qrcodeImgSize'></img> : ''
                                }
                                
                                
                            </div>
                        </div>
                        
                    </div>
                    <div className='passTipContent'>
                        <div className='passTipTitle'>
                        2. 输入您投递时所用的手机号即可进入面试流程，极速完成面试
                        </div>
                        <div className='passTipImg'>
                            <img src='https://public-static-assets.oss-cn-beijing.aliyuncs.com/img/elidouble.png'></img>
                        </div>
                    </div>
                </div>
            </div>
        </StyledQrcode>
    )
}

export default QrcodeUI